//#region GENERAL
/* eslint-disable */
export enum PATH {
  HOME = '/',
  PARTNER = '/partner',
  PRODUCT = '/product',
  PROMO = '/promo',
  STARX_FLYER = '/starx-flyer',
  PROMO_NEW_CAR = '/promo/mobil-penumpang-baru',
  PROMO_TRADE_CAR = '/promo/mobil-niaga-baru',
  PROMO_DANA_TUNAI = '/promo/dana-tunai',
  PROMO_MODAL_USAHA = '/promo/modal-usaha',
  PROMO_DETAIL = '/promo/:promo_slug',
  SERVICE_PROVIDER = '/service_provider',
  SERVICE_PROVIDER_ALL = '/service_provider/all',
  PRIVACY_POLICY = '/privacy_policy',
  DANA_TUNAI = '/dana-tunai',
  FAQ = '/faq',
  UPDATE_PROFILE = '/update_phone/:token',
  ERROR_PAGE = '/error/:code',
  PAGE_404 = '/error?code=404',

  //SOCIAL MEDIA LINK
  FACEBOOK = 'https://www.facebook.com/profile.php?id=100011540796604',
  INSTAGRAM = 'https://www.instagram.com/dipostarfinance/',
  YOUTUBE = 'https://www.youtube.com/channel/UCVLQpp889wXao5toUd4qABA',
  WEBSITE = 'https://www.dipostar.com/',

  // EXTERNAL LINK
  PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.dipostar.starx',
  APP_STORE = 'https://apps.apple.com/id/app/star-xperience/id1574595054',
  REGISTER_SP = '/serviceprovider/authentication/registration',
  LOGIN_SP = '/serviceprovider/authentication/login',
  CMS_HOME = '/serviceprovider/dashboard',

  // DEEP LINK
  DEEP_LINK = '/deep-link/:code',
  DEEP_LINK_REDIRECT = 'https://onelink.to/starx.deeplink',

  // BPKB OWNER
  BPKB_OWNER = '/bpkb-owner',
  BPKB_OWNER_SPOUSE = '/bpkb-owner/spouse',
  BPKB_THANK_YOU = '/bpkb-owner/thankyou',
  BPKB_OWNER_REVISE = '/bpkb-owner/revise',

  LIVENESS = '/liveness',
}

export const DEEPLINK_LIST = [
  'perbaikan',
  'perawatan',
  'perpanjangan-stnk',
  'kontrak',
  'tambah-provider',
  'kendaraan-saya',
  'profile',
  'aktifitas',
  'riwayat',
  'dana-tunai',
  'notifikasi',
  'membership',
  'check-phone-number',
  'login-first-screen',
  'kontrak-tab',
  'modal-usaha',
  'mocil-price-sell',
  'extension-stnk-list',
  'reservasi-bpkb',
  'insurance-list-screen',
  'layanan-pelanggan',
  'cek-harga-mobil',
  'promo',
  'news',
  'kredit-mobil-bekas',
  'list-reward-screen',
  'unduh-formulir',
  'car-trade-in',
  'simulation-credits',
  'simulation-car-credits',
  'simulation-car-models',
  'renew-phone-number',
]

// Since we've migrated to Appsflyer for deep-linking, we need to do mapping
// of the defined destination URL in Appsflyer configuration.
// The object key below is the URL that's accessed by customer in StarX Website,
// while the object value is the defined destination URL in Appsflyer.
// https://365dipostar-my.sharepoint.com/:x:/g/personal/erik_satriawan_dipostar_com/EZUriBwHk8VCt7_RV21q0b4BQhJB3JJX_81RdIGzHEcayg?e=GclAMT
export const APPSFLYER_LIST = {
  'check-phone-number': 'registrasi', 
  'login-first-screen': 'login',
  'profile': 'profile',
  'home': 'home',
  'simulation-car-models': 'simulasikredit',
  'simulation-car-credits': 'simulasikredit',
  'kontrak-tab': 'kontraksaya',
  'dana-tunai': 'danatunai',
  'modal-usaha': 'modalusaha',
  'mocil-price-sell': 'jualmobil',
  'extension-stnk-list': 'perpanjanganstnk',
  'reservasi-bpkb': 'pengambilanbpkb',
  'insurance-list-screen': 'insurance',
  'layanan-pelanggan': 'layananpelanggan',
  'cek-harga-mobil': 'cekhargamobil',
  'promo': 'promoh14ehf41',
  'news': 'news7z7tjl8',
  'notifikasi': 'notifikasi',
  'kendaraan-saya': 'kendaraansaya',
  'kredit-mobil-bekas': 'kreditmobilbekas',
  'list-reward-screen': 'claimreward',
  'unduh-formulir': 'unduhformulir',
  'car-trade-in': 'tukartambah'
}

export const EXCEPTIONAL_PROPERTYNAME = [
  'al:android:package',
  'al:android:url',
  'al:ios:url',
]
export const META_TAG_LIST = [
  {
    propertyName: 'al:ios:url',
    content: 'starx://deep-link/{XXX}',
  },
  {
    propertyName: 'al:ios:app_store_id',
    content: '1574595054',
  },
  {
    propertyName: 'al:ios:app_name',
    content: 'Star Xperience',
  },
  {
    propertyName: 'al:android:url',
    content: 'starx://deep-link/{XXX}',
  },
  {
    propertyName: 'al:android:package',
    content: '',
  },
  {
    propertyName: 'al:android:app_name',
    content: 'Star Xperience',
  },
  {
    propertyName: 'og:title',
    content: 'StarX - Deep-Link',
  },
  {
    propertyName: 'og:type',
    content: 'website',
  },
]
/* eslint-enable */
//#endregion
